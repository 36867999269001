import {
  createPagedResponseSchema,
  formatQueryParams,
  PagedRequestParams,
  PagedResponse,
} from '@top-solution/microtecnica-utils';
import { PnProgram, PnProgramAddForm, PnProgramSchema } from '../entities/PnProgram';
import { api, TAG_TYPES } from './baseApi';

const url = 'v1/pn-programs';
const tags = [{ type: TAG_TYPES.PN_PROGRAM, id: 'LIST' }];

const pnProgramApi = api.injectEndpoints({
  endpoints: (builder) => ({
    readPnProgramList: builder.query<PagedResponse<PnProgram>, PagedRequestParams>({
      query: (params) => ({ url, params: formatQueryParams(params) }),
      transformResponse: (data) => createPagedResponseSchema(PnProgramSchema).parse(data),
      providesTags: tags,
    }),
    createPnPnProgram: builder.mutation<void, PnProgramAddForm>({
      query: (body) => ({ url, method: 'POST', body }),
      invalidatesTags: tags,
    }),
    removePnProgram: builder.mutation<void, PnProgram>({
      query: (pnProgram) => ({ url: `${url}/${pnProgram.programId}/${pnProgram.pn}`, method: 'DELETE' }),
      invalidatesTags: tags,
    }),
  }),
  overrideExisting: false,
});

export const { useReadPnProgramListQuery, useCreatePnPnProgramMutation, useRemovePnProgramMutation } = pnProgramApi;
