import { useCallback, useState } from 'react';
import { ConfirmDialog } from '@top-solution/microtecnica-mui';
import Button, { ButtonProps } from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import { useSendSoldPnEmailMutation } from '../../services/soldPnApi';
import { PmNotificationList } from './PmNotificationList';
import { useReadProgramPmUsernameList } from './useReadProgramPmUsernameList';

type NotifyMissingRecipientButtonProps = Omit<ButtonProps, 'children' | 'onClick'> & {
  pn: string;
  programId: string;
  enditem: string;
};

export function NotifyMissingRecipientButton(props: NotifyMissingRecipientButtonProps): JSX.Element {
  const { pn, programId, enditem, ...buttonProps } = props;
  const readPmNotificationList = useReadProgramPmUsernameList(programId);
  const [open, setOpen] = useState(false);
  const [send, sendStatus] = useSendSoldPnEmailMutation();

  const handleSubmit = useCallback(async () => {
    await send({ pn, programId, enditem }).unwrap();
    setOpen(false);
  }, [enditem, pn, programId, send]);

  return (
    <>
      <Button color="secondary" size="small" onClick={() => setOpen(true)} {...buttonProps}>
        Notifica PM
      </Button>
      <ConfirmDialog
        title="Conferma invio notifica ai PM"
        description={
          readPmNotificationList.isLoading ? (
            <Skeleton variant="text" />
          ) : readPmNotificationList.data ? (
            <>
              <p>I seguenti indirizzi riceveranno una notifica per la mancanza di informazioni customer e ship to:</p>
              <PmNotificationList programId={programId} />
            </>
          ) : undefined
        }
        inProgress={sendStatus.isLoading}
        error={sendStatus.error ?? readPmNotificationList.error}
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={handleSubmit}
        confirmText="invia notifica"
        confirmButtonText="Invia"
      />
    </>
  );
}
