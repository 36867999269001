import { useCallback, useMemo, useState } from 'react';
import {
  DataGrid,
  DataGridProps,
  ErrorAlert,
  muiFiltersToPagedRequestFilters,
  usePagination,
} from '@top-solution/microtecnica-mui';
import { GridFilterModel, GridLogicOperator, GridSortModel } from '@mui/x-data-grid-premium';
import { useReadEndUserListQuery } from '../../services/endUserApi';
import { useEndUserListGridColumns } from './useEndUserListGridColumns';

type EndUserListGridProps = Omit<
  DataGridProps,
  | 'rows'
  | 'columns'
  | 'loading'
  | 'error'
  | 'pagination'
  | 'paginationMode'
  | 'rowCount'
  | 'page'
  | 'onPageChange'
  | 'pageSize'
  | 'onPageSizeChange'
  | 'sortingMode'
  | 'sortModel'
  | 'onSortModelChange'
  | 'filterMode'
  | 'onFilterModelChange'
  | 'filterModel'
> & {
  columns?: DataGridProps['columns'];
  pmFilter?: string;
};

export function EndUserListGrid(props: EndUserListGridProps): JSX.Element {
  const { columns, pmFilter, ...gridProps } = props;
  const defaultColumns = useEndUserListGridColumns();

  const { paginationModel, setPaginationModel } = usePagination(0);
  const [sortModel, setSortModel] = useState<GridSortModel>(() =>
    pmFilter ? [{ field: 'lastCheck', sort: 'asc' }] : [{ field: 'name', sort: 'asc' }],
  );
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [], logicOperator: GridLogicOperator.And });
  const readEndUserListParams = useMemo(
    () => ({
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
      sort: sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field}`),
      filters: muiFiltersToPagedRequestFilters(filterModel.items),
      pm: pmFilter,
    }),
    [filterModel.items, paginationModel.page, paginationModel.pageSize, pmFilter, sortModel],
  );
  const endUserList = useReadEndUserListQuery(readEndUserListParams);

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  if (endUserList.error) {
    return <ErrorAlert error={endUserList.error} />;
  }

  return (
    <DataGrid
      rows={endUserList.data?.data ?? []}
      columns={columns ?? defaultColumns}
      sessionStorageId="EndUserListGridDataGrid"
      loading={endUserList.isFetching}
      pagination
      paginationMode="server"
      rowCount={endUserList.data?.total ?? 0}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      filterMode="server"
      onFilterModelChange={handleFilterModelChange}
      filterModel={filterModel}
      {...gridProps}
      sx={{
        '[data-field="pmOeList"],[data-field="pmMroList"]': {
          gap: 0.25,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      }}
    />
  );
}
