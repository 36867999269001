import { z } from 'zod';

export enum ProcType {
  E = 'E',
  F = 'F',
  X = 'X',
}

export enum PnProgramSource {
  Manual = 'MANUAL',
  MMUser = 'MMUSER',
}

export const PnProgramSchema = z.object({
  pn: z.string(),
  programId: z.string(),
  procType: z.nativeEnum(ProcType),
  isMake: z.boolean(),
  source: z.nativeEnum(PnProgramSource),
});

export type PnProgram = z.infer<typeof PnProgramSchema>;

export const PnProgramAddFormSchema = z.object({
  pn: PnProgramSchema.shape.pn.min(2),
  programId: PnProgramSchema.shape.programId.min(2),
  procType: PnProgramSchema.shape.procType,
});

export type PnProgramAddForm = z.infer<typeof PnProgramAddFormSchema>;
