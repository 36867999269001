import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { EndUser, EndUserForm, EndUserFormSchema } from '../../entities/EndUser';
import { useUpdateEndUserMutation } from '../../services/endUserApi';
import { SaveIcon } from '../Icons';
import { EndUserEditForm } from './EndUserEditForm';

type EndUserEditDialogProps = Omit<DialogProps, 'onClose'> & {
  data: EndUser;
  onClose: (value?: EndUserForm & { id: EndUser['id'] }) => void;
};

export function EndUserEditDialog(props: EndUserEditDialogProps): JSX.Element {
  const { data: endUser, onClose, TransitionProps, ...dialogProps } = props;
  const [update, updateStatus] = useUpdateEndUserMutation();
  const form = useForm<EndUserForm>({
    defaultValues: {
      ...endUser,
      pmMroList: endUser.pmMroList.map(({ username }) => username),
      pmOeList: endUser.pmOeList.map(({ username }) => username),
      type: endUser.type ?? null,
    },
    resolver: zodResolver(EndUserFormSchema),
  });

  const onSubmit = useCallback(
    async (data: EndUserForm) => {
      const payload = {
        ...data,
        id: endUser.id,
      };
      await update(payload).unwrap();
      onClose(payload);
    },
    [endUser.id, onClose, update],
  );

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      {...dialogProps}
      TransitionProps={{
        ...TransitionProps,
        onExit: (node) => {
          form.reset();
          updateStatus.reset();
          TransitionProps?.onExit?.(node);
        },
      }}
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogTitle>Modifica end-user {endUser.id}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', '.MuiFormControl-root': { mt: 1 }, gap: 1 }}>
          <EndUserEditForm form={form} />
          {updateStatus.error && <ErrorAlert error={updateStatus.error} />}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => onClose()}>
            Annulla
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            loading={updateStatus.isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Salva
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
