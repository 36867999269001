import { createMdiIcon } from '@top-solution/microtecnica-mui';
import {
  mdiAccountAlert,
  mdiAccountCash,
  mdiAccountCog,
  mdiAccountTieHat,
  mdiAccountTie,
  mdiAirplane,
  mdiBookmarkCheck,
  mdiCashMultiple,
  mdiDatabaseImport,
  mdiDolly,
  mdiEarth,
  mdiFileDocumentMultiple,
  mdiFlag,
  mdiGraph,
  mdiHammerScrewdriver,
  mdiHandshake,
  mdiHistory,
  mdiLicense,
  mdiScriptText,
  mdiShieldHalfFull,
  mdiSourceBranch,
  mdiStore,
  mdiAirplaneSearch,
} from '@mdi/js';
import { AppSection } from '../entities/AppSection';
import { UserRole } from '../entities/User';

const ThirdPartySectionIcon = createMdiIcon(mdiAccountAlert);
const PurchaseGroupSectionIcon = createMdiIcon(mdiAccountCash);
const UserSectionIcon = createMdiIcon(mdiAccountCog);
const EndUserSectionIcon = createMdiIcon(mdiAccountTieHat);
const CustomerSectionIcon = createMdiIcon(mdiAccountTie);
const ProgramSectionIcon = createMdiIcon(mdiAirplane);
const SerniCategorySectionIcon = createMdiIcon(mdiBookmarkCheck);
const PurchaseOrderSectionIcon = createMdiIcon(mdiCashMultiple);
const ImportStatusIcon = createMdiIcon(mdiDatabaseImport);
const RecipientSectionIcon = createMdiIcon(mdiDolly);
const ForeignClassificationSectionIcon = createMdiIcon(mdiEarth);
const PnProgramSectionIcon = createMdiIcon(mdiAirplaneSearch);
const TechDataClassificationSectionIcon = createMdiIcon(mdiFileDocumentMultiple);
const ItalianClassificationSectionIcon = createMdiIcon(mdiFlag);
const TopDownSearchIcon = createMdiIcon(mdiGraph);
const AdminSectionIcon = createMdiIcon(mdiHammerScrewdriver);
const SupplierSectionIcon = createMdiIcon(mdiHandshake);
const HistoryIcon = createMdiIcon(mdiHistory);
const ExportLicenceIcon = createMdiIcon(mdiLicense);
const EUSSectionIcon = createMdiIcon(mdiScriptText);
const ECCNSectionIcon = createMdiIcon(mdiShieldHalfFull);
const BottomUpSearchIcon = createMdiIcon(mdiSourceBranch);
const VendorSectionIcon = createMdiIcon(mdiStore);

export const bottomUpSearch: AppSection = {
  url: '/query/bottom-up',
  title: 'Ricerca bottom-up',
  icon: <BottomUpSearchIcon />,
};

export const topDownSearch: AppSection = {
  url: '/query/top-down',
  title: 'Ricerca top-down',
  icon: <TopDownSearchIcon />,
};

export const adminSection: AppSection = {
  url: '/admin',
  title: 'Amministrazione',
  icon: <AdminSectionIcon />,
};

export const vendorSection: AppSection = {
  url: '/admin/vendors',
  title: 'Vendor',
  icon: <VendorSectionIcon />,
  role: UserRole.VENDOR_MANAGER,
};

export const purchaseGroupSection: AppSection = {
  url: '/admin/purchase-groups',
  title: 'Purchase Group',
  icon: <PurchaseGroupSectionIcon />,
  role: UserRole.VENDOR_MANAGER,
};

export const pnProgramSection: AppSection = {
  url: '/admin/pn-programs',
  title: 'Associazione P/N Program',
  icon: <PnProgramSectionIcon />,
  role: UserRole.PROGRAM_MANAGER,
};

export const purchaseOrderSection: AppSection = {
  url: '/admin/purchase-orders',
  title: 'Purchase Order',
  icon: <PurchaseOrderSectionIcon />,
  role: UserRole.VENDOR_MANAGER,
};

export const customerSection: AppSection = {
  url: '/admin/customers',
  title: 'Customer',
  icon: <CustomerSectionIcon />,
  role: UserRole.PROGRAM_MANAGER,
};

export const endUserSection: AppSection = {
  url: '/admin/end-users',
  title: 'Program – End User',
  icon: <EndUserSectionIcon />,
  role: UserRole.PROGRAM_MANAGER,
};
export const recipientSection: AppSection = {
  url: '/admin/recipients',
  title: 'End Item – Customer',
  icon: <RecipientSectionIcon />,
  role: UserRole.PROGRAM_MANAGER,
};

export const programSection: AppSection = {
  url: '/admin/programs',
  title: 'Programmi',
  icon: <ProgramSectionIcon />,
};

export const supplierSection: AppSection = {
  url: '/admin/suppliers',
  title: 'Associazione P/N, Vendor, Buyer',
  icon: <SupplierSectionIcon />,
  role: UserRole.VENDOR_MANAGER,
};

export const eccnSection: AppSection = {
  url: '/admin/eccn',
  title: 'ECCN',
  icon: <ECCNSectionIcon />,
  role: UserRole.ITALIAN_CLASSIFICATION_MANAGER,
};

export const serniCategorySection: AppSection = {
  url: '/admin/serni-categories',
  title: 'Categoria SERNI',
  icon: <SerniCategorySectionIcon />,
  role: UserRole.ITALIAN_CLASSIFICATION_MANAGER,
};

export const italianClassificationSection: AppSection = {
  url: '/admin/italian-classifications',
  title: 'Classificazione italiana',
  icon: <ItalianClassificationSectionIcon />,
  role: UserRole.ITALIAN_CLASSIFICATION_MANAGER,
};

export const techDataClassificationSection: AppSection = {
  url: '/admin/tech-data-classifications',
  title: 'Classificazione Tech Data',
  icon: <TechDataClassificationSectionIcon />,
  role: UserRole.ITALIAN_CLASSIFICATION_MANAGER,
};

export const foreignClassificationSection: AppSection = {
  url: '/admin/foreign-classifications',
  title: 'Classificazione estera',
  icon: <ForeignClassificationSectionIcon />,
  role: UserRole.FOREIGN_CLASSIFICATION_MANAGER,
};

export const thirdPartySection: AppSection = {
  url: '/admin/third-parties',
  title: 'Terze parti',
  icon: <ThirdPartySectionIcon />,
  role: UserRole.THIRD_PARTY_MANAGER,
};

export const userSection: AppSection = {
  url: '/admin/users',
  title: 'Utenti',
  icon: <UserSectionIcon />,
  role: UserRole.ADMIN,
};

export const importStatusSection: AppSection = {
  url: '/admin/import-status',
  title: 'Stato import dati',
  icon: <ImportStatusIcon />,
  role: UserRole.ADMIN,
};

export const eusSection: AppSection = {
  url: '/eus',
  title: 'End User Statement',
  icon: <EUSSectionIcon />,
};

export const italianExportLicense: AppSection = {
  url: '/export-licence',
  title: 'Italian Export License',
  icon: <ExportLicenceIcon />,
};

export const historySection: AppSection = {
  url: '/admin/history',
  title: 'Storico modifiche',
  icon: <HistoryIcon />,
};
