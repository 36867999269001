import { ConfirmDialogProps } from '@top-solution/microtecnica-mui';
import { FilterOperator } from '@top-solution/microtecnica-utils';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { useReadSoldPnListQuery } from '../../services/soldPnApi';
import { DefinitionList } from '../DefinitionList';
import { PmNotificationList } from './PmNotificationList';
import { SoldPnApprovalsList } from './SoldPnApprovalsList';

type RecipientManualInfoDialogProps = Pick<ConfirmDialogProps, 'open'> & {
  pn: string;
  programId: string;
  customerName: string;
  customerId: string;
  shipToName: string;
  shipToId: string;
  onClose: () => void;
};

export function RecipientManualInfoDialog(props: RecipientManualInfoDialogProps): JSX.Element {
  const { pn, programId, customerName, customerId, shipToName, shipToId, onClose, ...dialogProps } = props;
  const readSoldPnList = useReadSoldPnListQuery({
    offset: 0,
    limit: 10 ** 6,
    filters: [
      {
        value: pn,
        field: 'pn',
        operator: FilterOperator.equals,
      },
      {
        value: programId,
        field: 'programId',
        operator: FilterOperator.equals,
      },
      {
        value: customerId,
        field: 'customerId',
        operator: FilterOperator.equals,
      },
      {
        value: shipToId,
        field: 'shipToId',
        operator: FilterOperator.equals,
      },
      {
        value: 'false',
        field: 'fromPir',
        operator: FilterOperator.equals,
      },
      {
        value: 'false',
        field: 'fromSos',
        operator: FilterOperator.equals,
      },
      {
        value: 'false',
        field: 'fromMro',
        operator: FilterOperator.equals,
      },
    ],
  });

  return (
    <Dialog fullWidth maxWidth="sm" {...dialogProps}>
      <DialogTitle>Verifica associazione flusso PIR</DialogTitle>
      <DialogContent>
        <Stack gap={1}>
          <DefinitionList
            data={[
              { term: 'P/N', definition: pn },
              { term: 'Program', definition: programId },
              { term: 'Customer', definition: customerName },
              { term: 'Ship to', definition: shipToName },
            ]}
          />
          <PmNotificationList programId={programId} />
          {readSoldPnList.data?.data[0]?.approvals?.length && (
            <SoldPnApprovalsList approvals={readSoldPnList.data?.data[0]?.approvals} />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => onClose()}>
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
}
