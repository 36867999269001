import { useCallback } from 'react';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { SoldPn } from '../../entities/SoldPn';
import { useRemoveSoldPnMutation } from '../../services/soldPnApi';
import { DefinitionList } from '../DefinitionList';
import { PmNotificationList } from './PmNotificationList';
import { useCanManageSoldPn } from './useCanManageSoldPn';

type RecipientDeleteConfirmProps = Omit<DialogProps, 'onClose'> & {
  soldPn: SoldPn;
  onClose: (value?: SoldPn) => void;
};

export function RecipientDeleteConfirm(props: RecipientDeleteConfirmProps): JSX.Element {
  const { soldPn, onClose, ...dialogProps } = props;
  const [remove, removeStatus] = useRemoveSoldPnMutation();
  const canDelete = useCanManageSoldPn(soldPn.programId);

  const handleConfirm = useCallback(async () => {
    await remove({
      pn: soldPn.pn,
      programId: soldPn.programId,
      customerId: soldPn.customer?.id ?? null,
      shipToId: soldPn.shipTo?.id ?? null,
    }).unwrap();
    onClose(soldPn);
  }, [remove, soldPn, onClose]);

  return (
    <Dialog fullWidth maxWidth="sm" {...dialogProps}>
      <DialogTitle>Vuoi davvero eliminare l&apos;associazione selezionata?</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <DefinitionList
            data={[
              { term: 'P/N', definition: soldPn.pn },
              { term: 'Program', definition: soldPn.programId },
              { term: 'Customer', definition: soldPn.customer?.name },
              { term: 'Ship to', definition: soldPn.shipTo?.name },
            ]}
          />
          <PmNotificationList programId={soldPn.programId}>
            {!canDelete && (
              <Alert severity="warning">
                Devi essere un PM di questo programma per poter eliminare l&apos;associazione
              </Alert>
            )}
          </PmNotificationList>
          {removeStatus.error && <ErrorAlert error={removeStatus.error} />}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => onClose()}>
          Annulla
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          color="error"
          disabled={!canDelete}
          loading={removeStatus.isLoading}
          onClick={handleConfirm}
        >
          Elimina
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
