import { useCallback } from 'react';
import { ConfirmDialogProps, ErrorAlert } from '@top-solution/microtecnica-mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { ManualSoldPn } from '../../entities/SoldPn';
import { useCreateSoldPnMutation } from '../../services/soldPnApi';
import { DefinitionList } from '../DefinitionList';
import { PmNotificationList } from './PmNotificationList';
import { SoldPnApprovalsList } from './SoldPnApprovalsList';
import { useCanManageSoldPn } from './useCanManageSoldPn';

type RecipientDialoCheckProps = Pick<ConfirmDialogProps, 'open'> & {
  soldPn: ManualSoldPn;
  onClose: () => void;
};

export function RecipientCheckDialog(props: RecipientDialoCheckProps): JSX.Element {
  const { soldPn, onClose, ...dialogProps } = props;
  const [create, createStatus] = useCreateSoldPnMutation();
  const canApprove = useCanManageSoldPn(soldPn.programId);

  const handleApproveClick = useCallback(async () => {
    await create(soldPn).unwrap();
    onClose();
  }, [create, onClose, soldPn]);

  return (
    <Dialog fullWidth maxWidth="sm" {...dialogProps}>
      <DialogTitle>Verifica associazione manuale {soldPn.pn}</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <DefinitionList
            data={[
              { term: 'P/N', definition: soldPn.pn },
              { term: 'Program', definition: soldPn.programId },
              { term: 'Customer', definition: soldPn.customer.name },
              { term: 'Ship to', definition: soldPn.shipTo.name },
            ]}
          />
          <PmNotificationList programId={soldPn.programId} />
          {soldPn.approvals?.length && <SoldPnApprovalsList approvals={soldPn.approvals} />}
          {createStatus.error && <ErrorAlert error={createStatus.error} />}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => onClose()}>
          Chiudi
        </Button>
        {canApprove && (
          <Button variant="contained" color="primary" onClick={() => handleApproveClick()}>
            Approva
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
