import { z } from 'zod';

export const DAYS_TO_EXPIRE = 90;

export enum EndUserType {
  Intermediate = 'intermediate',
  Ultimate = 'ultimate',
}
export const endUserTypeLabel: Record<EndUserType, string> = {
  [EndUserType.Intermediate]: 'Intermediate consignee',
  [EndUserType.Ultimate]: 'Ultimate consignee',
};

export const PmListSchema = z
  .object({
    username: z.string(),
    lastCheck: z.string().datetime({ offset: true }).optional(),
  })
  .array();

export const EndUserSchema = z.object({
  id: z.number(),
  name: z.string().min(3),
  country: z.string().min(2).max(3),
  address: z.string(),
  programId: z.string(),
  pmMroList: PmListSchema,
  pmOeList: PmListSchema,
  type: z.nativeEnum(EndUserType).optional(),
  lastCheck: z.string().datetime({ offset: true }).optional(),
});

export type EndUser = z.infer<typeof EndUserSchema>;

export const EndUserFormSchema = EndUserSchema.omit({
  id: true,
  type: true,
  pmMroList: true,
  pmOeList: true,
  lastCheck: true,
}).extend({
  pmMroList: z.string().array(),
  pmOeList: z.string().array(),
  type: EndUserSchema.shape.type.unwrap().nullable(),
});

export type EndUserForm = z.infer<typeof EndUserFormSchema>;
