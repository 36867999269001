import { useMemo } from 'react';
import { FilterOperator } from '@top-solution/microtecnica-utils';
import { useReadEndUserListQuery } from '../../services/endUserApi';

type UseReadProgramPmUsernameList = ReturnType<typeof useReadEndUserListQuery> & {
  mro: string[];
  oe: string[];
};

export function useReadProgramPmUsernameList(programId: string): UseReadProgramPmUsernameList {
  const readEndUserListParams = useMemo(
    () => ({
      offset: 0,
      limit: 10 ** 6,
      filters: [
        {
          value: programId,
          field: 'programId',
          operator: FilterOperator.equals,
        },
      ],
    }),
    [programId],
  );

  const readEndUserList = useReadEndUserListQuery(readEndUserListParams);

  const { mro, oe } = useMemo<{ mro: string[]; oe: string[] }>(() => {
    const mroSet = new Set<string>();
    const oeSet = new Set<string>();
    readEndUserList.data?.data.forEach((endUser) => {
      endUser.pmMroList.forEach(({ username }) => mroSet.add(username));
      endUser.pmOeList.forEach(({ username }) => mroSet.add(username));
    });

    return { mro: [...mroSet], oe: [...oeSet] };
  }, [readEndUserList.data?.data]);
  return {
    ...readEndUserList,
    mro,
    oe,
  };
}
