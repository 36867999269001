import { AuthGuard, useAuth } from '@top-solution/microtecnica-utils';
import blueGrey from '@mui/material/colors/blueGrey';
import deepPurple from '@mui/material/colors/deepPurple';
import green from '@mui/material/colors/green';
import indigo from '@mui/material/colors/indigo';
import teal from '@mui/material/colors/teal';
import Stack from '@mui/material/Stack';
import { AppSectionListRow } from '../../components/AppSectionListRow';
import { Layout } from '../../components/Layout';
import { UserRole } from '../../entities/User';
import UnauthorizedPage from '../Error/UnauthorizedPage';
import {
  adminSection,
  importStatusSection,
  userSection,
  vendorSection,
  purchaseGroupSection,
  purchaseOrderSection,
  supplierSection,
  programSection,
  pnProgramSection,
  customerSection,
  endUserSection,
  recipientSection,
  eccnSection,
  serniCategorySection,
  italianClassificationSection,
  foreignClassificationSection,
  techDataClassificationSection,
  thirdPartySection,
  historySection,
} from '../sections';

const breadcrumbs = [{ title: adminSection.title, url: adminSection.url }];

export function AdminPage(): JSX.Element {
  const { isAdmin, hasRole } = useAuth();
  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />}>
      {hasRole(UserRole.USER) ? (
        <UnauthorizedPage />
      ) : (
        <Layout breadcrumbs={breadcrumbs} sx={{ padding: 2 }} disableGutters>
          <Stack
            sx={{
              gap: 2,
              flexDirection: { xs: 'column', lg: 'row' },
              '.MuiStack-root': {
                flexDirection: { xs: 'row', lg: 'column' },
              },
            }}
          >
            <AppSectionListRow list={[programSection]} color={indigo[500]} />
            <AppSectionListRow
              list={[vendorSection, purchaseGroupSection, supplierSection, purchaseOrderSection]}
              color={deepPurple[500]}
            />
            <AppSectionListRow
              list={[pnProgramSection, customerSection, endUserSection, recipientSection]}
              color={teal[500]}
            />
            <AppSectionListRow
              list={[italianClassificationSection, techDataClassificationSection, eccnSection, serniCategorySection]}
              color={green[900]}
            />
            <AppSectionListRow list={[foreignClassificationSection]} color={green[800]} />
            <AppSectionListRow list={[thirdPartySection]} color={green[600]} />
            {
              <AppSectionListRow
                list={[historySection, importStatusSection, ...(isAdmin ? [userSection] : [])]}
                color={blueGrey[500]}
              />
            }
          </Stack>
        </Layout>
      )}
    </AuthGuard>
  );
}
