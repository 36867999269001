import { useMemo } from 'react';
import { useCountryGridColDef, useProgramGridColDef, UserLabel } from '@top-solution/microtecnica-mui';
import { differenceInCalendarDays } from 'date-fns';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { DAYS_TO_EXPIRE, EndUser, endUserTypeLabel } from '../../entities/EndUser';
import { stringDateColumn } from '../DataGrid';
import { AlertIcon, CheckIcon } from '../Icons';

const userListColumn: GridColDef = {
  field: 'username',
  display: 'flex',
  renderCell: ({ value }) => {
    if (Array.isArray(value)) {
      return value.map((item) => {
        if (item && 'username' in item && typeof item.username === 'string') {
          const lastCheck = 'lastCheck' in item && typeof item.lastCheck === 'string' ? new Date(item.lastCheck) : null;
          const daysFromLastCheck = lastCheck ? differenceInCalendarDays(new Date(), lastCheck) : null;
          return (
            <Tooltip
              key={item.username}
              title={
                lastCheck === null ? 'Verifica non effettuata' : `Ultima verifica: ${lastCheck.toLocaleDateString()}`
              }
              disableInteractive
            >
              <Chip
                size="small"
                color={
                  lastCheck === null
                    ? 'error'
                    : daysFromLastCheck && daysFromLastCheck > DAYS_TO_EXPIRE
                      ? 'warning'
                      : undefined
                }
                label={<UserLabel username={item.username} variant="body2" />}
              />
            </Tooltip>
          );
        } else {
          return item;
        }
      });
    }
    return value;
  },
  sortable: false,
};

export function useEndUserListGridColumns(): GridColDef[] {
  const countryColumn = useCountryGridColDef({ format: 'png' });
  const programColumn = useProgramGridColDef();
  return useMemo(
    () => [
      { field: 'id', headerName: 'Code', width: 80 },
      { ...programColumn, width: 150 },
      { field: 'name', headerName: 'Nome', width: 240 },
      {
        field: 'type',
        headerName: 'Ultimate/Intermediate',
        type: 'singleSelect',
        valueOptions: Object.entries(endUserTypeLabel).map(([value, label]) => ({ value, label })),
        width: 200,
      },
      { ...countryColumn, width: 180 },
      { field: 'address', headerName: 'Indirizzo', width: 300, sortable: false },
      {
        ...stringDateColumn,
        field: 'lastCheck',
        headerName: 'Ultima verifica',
        width: 200,
        renderCell: ({ value, formattedValue }: GridRenderCellParams<EndUser, Date, string>) => {
          if (value) {
            const expired = differenceInCalendarDays(new Date(), value) > DAYS_TO_EXPIRE;
            return (
              <Chip
                size="small"
                variant="outlined"
                color={expired ? 'warning' : 'success'}
                label={formattedValue}
                icon={expired ? <AlertIcon /> : <CheckIcon />}
              />
            );
          } else {
            return (
              <Chip
                size="small"
                variant="outlined"
                color="error"
                label="Verifica non effettuata"
                icon={<AlertIcon />}
              />
            );
          }
        },
      },
      { ...userListColumn, field: 'pmOeList', headerName: 'PM OE', width: 300 },
      { ...userListColumn, field: 'pmMroList', headerName: 'PM MRO', width: 300 },
    ],
    [countryColumn, programColumn],
  );
}
