import { useMemo } from 'react';
import { useAuth } from '@top-solution/microtecnica-utils';
import { useReadProgramPmUsernameList } from './useReadProgramPmUsernameList';

export function useCanManageSoldPn(programId: string): boolean {
  const { username, isAdmin } = useAuth();
  const readPmList = useReadProgramPmUsernameList(programId);

  return useMemo<boolean>(() => {
    if (readPmList.oe && readPmList.mro) {
      return isAdmin || Boolean([...readPmList.oe, ...readPmList.mro].find((pm) => pm === username));
    }
    return isAdmin;
  }, [isAdmin, readPmList.mro, readPmList.oe, username]);
}
