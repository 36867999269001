import { useCallback, useMemo, useState } from 'react';
import {
  DataGrid,
  DataGridWrapper,
  useProgramGridColDef,
  usePagination,
  muiFiltersToPagedRequestFilters,
  DeleteConfirmDialog,
} from '@top-solution/microtecnica-mui';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {
  DataGridPremiumProps,
  GRID_ACTIONS_COLUMN_TYPE,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import { makeBuyColumn } from '../../../components/DataGrid';
import { AddIcon, DeleteIcon } from '../../../components/Icons';
import { Layout } from '../../../components/Layout';
import { PnProgram, PnProgramSource, ProcType } from '../../../entities/PnProgram';
import { UserRole } from '../../../entities/User';
import { useReadPnProgramListQuery, useRemovePnProgramMutation } from '../../../services/pnProgramApi';
import UnauthorizedPage from '../../Error/UnauthorizedPage';
import { adminSection, pnProgramSection } from '../../sections';
import { PnProgramAddDialog } from './PnProgramAddDialog';

const initialState: DataGridPremiumProps['initialState'] = {
  pinnedColumns: { right: [GRID_ACTIONS_COLUMN_TYPE] },
};

const breadcrumbs = [{ title: adminSection.title, url: adminSection.url }, { title: pnProgramSection.title }];

export function PnProgramListPage(): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [itemToRemove, setItemToRemove] = useState<PnProgram | null>(null);
  const [remove, removeStatus] = useRemovePnProgramMutation();

  const programColumn = useProgramGridColDef();

  const { paginationModel, setPaginationModel } = usePagination(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'pn', sort: 'asc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [], logicOperator: GridLogicOperator.And });

  const readPnProgramListParams = useMemo(
    () => ({
      limit: paginationModel.pageSize,
      offset: paginationModel.pageSize * paginationModel.page,
      sort: sortModel.map(({ field, sort }) => `${sort === 'desc' ? '-' : ''}${field}`),
      filters: muiFiltersToPagedRequestFilters(filterModel.items),
    }),
    [filterModel.items, paginationModel, sortModel],
  );
  const pnProgramList = useReadPnProgramListQuery(readPnProgramListParams);

  const columns = useMemo<GridColDef<PnProgram>[]>(
    () => [
      { field: 'pn', headerName: 'P/N', width: 250 },
      { ...programColumn, width: 150 },
      {
        field: 'procType',
        headerName: 'Proc Type',
        type: 'singleSelect',
        valueOptions: Object.values(ProcType),
        width: 100,
      },
      { ...makeBuyColumn },
      {
        field: 'source',
        headerName: 'Source',
        type: 'singleSelect',
        valueOptions: Object.values(PnProgramSource),
        width: 100,
      },
      {
        field: GRID_ACTIONS_COLUMN_TYPE,
        type: GRID_ACTIONS_COLUMN_TYPE,
        renderHeader: () => (
          <Tooltip title="Nuova associazione P/N Program">
            <IconButton
              color="primary"
              onClick={() => {
                setDialogOpen(true);
                setShowAddDialog(true);
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        ),
        renderCell: ({ row }) =>
          row.source === PnProgramSource.Manual && (
            <Tooltip title="Elimina associazione P/N Program">
              <IconButton
                onClick={() => {
                  setDialogOpen(true);
                  setItemToRemove(row);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ),
      },
    ],
    [programColumn],
  );

  const rows = useMemo(
    () =>
      pnProgramList.data?.data.map((pnProgram) => ({
        id: `${pnProgram.pn}+${pnProgram.programId}`,
        ...pnProgram,
      })) ?? [],
    [pnProgramList.data?.data],
  );

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSortModel(sortModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  const handleFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);
      setPaginationModel({ page: 0 });
    },
    [setPaginationModel],
  );

  return (
    <AuthGuard unauthorizedFallback={<UnauthorizedPage />} authorizeRole={(role) => role !== UserRole.USER}>
      <Layout
        maxWidth={false}
        breadcrumbs={breadcrumbs}
        inProgress={pnProgramList.isLoading}
        error={pnProgramList.error}
        disableGutters
        sx={{ p: 1 }}
      >
        <DataGridWrapper>
          <DataGrid
            rows={rows}
            columns={columns}
            sessionStorageId="PnPrgramListPageDataGrid"
            loading={pnProgramList.isFetching}
            pagination
            paginationMode="server"
            rowCount={pnProgramList.data?.total ?? 0}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={handleFilterModelChange}
            filterModel={filterModel}
            editMode="row"
            initialState={initialState}
          />
        </DataGridWrapper>
        {itemToRemove && (
          <DeleteConfirmDialog
            title={`Vuoi davvero eliminare l'associazione tra ${itemToRemove.programId} e ${itemToRemove.pn}?`}
            confirmText={itemToRemove.pn}
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            onConfirm={async () => {
              await remove(itemToRemove).unwrap();
              setDialogOpen(false);
            }}
            TransitionProps={{ onExited: () => setItemToRemove(null) }}
            inProgress={removeStatus.isLoading}
            error={removeStatus.error}
          />
        )}
        {showAddDialog && (
          <PnProgramAddDialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            TransitionProps={{ onExited: () => setShowAddDialog(false) }}
          />
        )}
      </Layout>
    </AuthGuard>
  );
}
