import { useMemo } from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/query';
import { rowIdSeparator } from '../../components/DataGrid';
import { bottomUpSearchHasThirdPartyWarning } from '../../entities/BottomUpSearch';
import { EUS } from '../../entities/EUS';
import { useBottomUpSearchQuery } from '../../services/searchApi';

export const USAWarningText =
  'All parties mentioned on this document shall be considered as applicable throughout the country of reference, therefore each party has to be intended for the specific address mentioned as well as “and all locations in that country”';

export function useHasUsMilitaryItems(eus?: EUS): {
  isLoading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  data: boolean | undefined;
} {
  const bottomUpSearchParams = useMemo(() => {
    if (eus) {
      return {
        fields: {
          enditem: true,
          program: true,
          customer: false,
          enduser: false,
          italianClassification: true,
          foreignClassification: true,
          techDataClassification: false,
          serni: false,
        },
        filters: {
          pn: eus.pnList.map(({ pn }) => pn),
          vendor: [],
          pgr: [],
          eccn: [],
        },
      };
    }
    return skipToken;
  }, [eus]);

  const bottomUpSearch = useBottomUpSearchQuery(bottomUpSearchParams);

  const data = useMemo(() => {
    if (eus && bottomUpSearch.data) {
      const eusData = new Set<string>();
      eus.pnList.forEach(({ pn, customerList }) => {
        customerList.forEach(({ enditem, programId }) => {
          eusData.add([pn, enditem, programId].join(rowIdSeparator));
        });
      });
      return bottomUpSearch.data.some(
        (row) =>
          eusData.has([row.item, row.enditem, row.programId].join(rowIdSeparator)) &&
          bottomUpSearchHasThirdPartyWarning(row),
      );
    }
  }, [bottomUpSearch.data, eus]);

  return {
    isLoading: bottomUpSearch.isLoading,
    error: bottomUpSearch.error,
    data,
  };
}
