import { useMemo, useState } from 'react';
import {
  Country,
  Program,
  useLazyReadItemInfoQuery,
  useReadCountriesQuery,
  useReadProgramsQuery,
} from '@top-solution/microtecnica-utils';
import { Workbook } from 'exceljs';
import { EUS } from '../../entities/EUS';
import { UnitOfMeasure } from '../../entities/EUSAttributes';
import { useReadUnitOfMeasureListQuery } from '../../services/eusAttributesApi';
import { USAWarningText, useHasUsMilitaryItems } from './useHasUsMilitaryItems';

const documentName = 'Attachment A';

interface CreateExcelParams {
  eus: EUS;
  hasUsMilitaryItems: boolean;
  title: string;
  programs: Record<Program['id'], Program>;
  unitOfMeasures: UnitOfMeasure[];
  countries: Record<Country['id'], Country>;
  enditems: Record<string, string>;
}

function createExcel({
  eus,
  hasUsMilitaryItems,
  title,
  programs,
  unitOfMeasures,
  countries,
  enditems,
}: CreateExcelParams): Workbook {
  const uom = unitOfMeasures.reduce(
    (map, uom) => map.set(uom.id, uom.name),
    new Map<UnitOfMeasure['id'], UnitOfMeasure['name']>(),
  );

  const workbook = new Workbook();
  workbook.title = title;
  workbook.creator = import.meta.env.VITE_APP_ID;
  workbook.created = new Date();

  const worksheet = workbook.addWorksheet(documentName);
  worksheet.properties.defaultColWidth = 20;

  const columnA = worksheet.getColumn('A');
  columnA.font = { bold: true };
  columnA.width = 20; // For some reason, setting defaultColWidth doesn't work for the first column
  worksheet.getRows(1, 3)?.forEach((row) => (row.font = { bold: true }));

  let lastRow = 1;
  worksheet.getCell(`D${lastRow++}`).value = `${documentName} / Allegato A`;
  worksheet.getCell(`A${lastRow++}`).value = eus.protocolNum;
  worksheet.getCell(`A${lastRow++}`).value = eus.eusReleaseDate && new Date(eus.eusReleaseDate);

  lastRow++;
  worksheet.getCell(`A${lastRow++}`).value = 'SEZIONE 1';
  let headersRow = worksheet.getRow(lastRow++);
  headersRow.font = { bold: true };
  headersRow.values = [
    'P/N',
    'End Item',
    'Descrizione End Item',
    'Programma',
    'Customer',
    'Customer country',
    'Customer address',
    'Ship to',
    'Ship to country',
    'Ship to address',
  ];
  eus.pnList.forEach((item) => {
    item.customerList?.forEach((customer) => {
      if (customer.enditem) {
        worksheet.getRow(lastRow++).values = [
          item.pn,
          customer.enditem,
          enditems[customer.enditem] ?? null,
          (customer.programId && programs[customer.programId]?.name) ?? customer.programId,
          customer.customer?.name,
          (customer.customer?.country && countries[customer.customer.country]?.name) ?? customer.customer?.country,
          customer.customer?.address,
          customer.shipTo?.name,
          (customer.shipTo?.country && countries[customer.shipTo.country]?.name) ?? customer.shipTo?.country,
          customer.shipTo?.address,
        ];
      }
    });
  });
  if (hasUsMilitaryItems) {
    worksheet.getRow(lastRow++).values = [USAWarningText];
  }

  lastRow++;
  worksheet.getCell(`A${lastRow++}`).value = 'SEZIONE 2';
  headersRow = worksheet.getRow(lastRow++);
  headersRow.font = { bold: true };
  headersRow.values = ['Programma', 'End user country', 'End user', 'End user address'];
  eus.enduserList.forEach((item) => {
    worksheet.getRow(lastRow++).values = [
      (item.enduser.programId && programs[item.enduser.programId]?.name) ?? item.enduser.programId,
      (item.enduser.country && countries[item.enduser.country]?.name) ?? item.enduser.country,
      item.enduser.name,
      item.enduser.address,
    ];
  });

  lastRow++;
  worksheet.getCell(`A${lastRow++}`).value = 'SEZIONE 3';
  headersRow = worksheet.getRow(lastRow++);
  headersRow.font = { bold: true };
  headersRow.values = ['Third Party - name', 'Third Party - type/role', 'Third Party - address', 'Third Party - notes'];
  eus.thirdPartyList.forEach((thirdParty) => {
    worksheet.getRow(lastRow++).values = [thirdParty.name, thirdParty.type.name, thirdParty.address, thirdParty.notes];
  });

  lastRow++;
  worksheet.getCell(`A${lastRow++}`).value = 'SEZIONE 4';
  headersRow = worksheet.getRow(lastRow++);
  headersRow.font = { bold: true };
  headersRow.values = ['P/N', 'P.O. (Purchase Order)', 'Quantity/ Quantità', 'UoM (unit of measure)/Unità di misura'];

  eus.pnList.forEach((item) => {
    item.poList.forEach((po) => {
      worksheet.getRow(lastRow++).values = [
        item.pn,
        po.po,
        po.qty,
        (po.unitOfMeasureId && uom.get(po.unitOfMeasureId)) ?? po.unitOfMeasureId,
      ];
    });
  });

  return workbook;
}

interface UseDownloadAttachmentA {
  download: null | (() => void);
  isLoading: boolean;
  isReady: boolean;
}

// TODO: handle status "RTK style" to display a loader on the button
export function useDownloadAttachmentA(eus: EUS): UseDownloadAttachmentA {
  const title = `EUS ${eus.protocolNum} - ${documentName}`;
  const readPrograms = useReadProgramsQuery();
  const readUom = useReadUnitOfMeasureListQuery();
  const readCountries = useReadCountriesQuery();
  const [readItemInfo] = useLazyReadItemInfoQuery();
  const hasUsMilitaryItems = useHasUsMilitaryItems(eus);

  const [isLoading, setIsLoading] = useState(false);
  const isReady = useMemo(
    () => !readPrograms.isLoading && !readUom.isLoading && !readCountries.isLoading,
    [readCountries.isLoading, readPrograms.isLoading, readUom.isLoading],
  );

  const download = useMemo(() => {
    if (readPrograms.data && readUom.data && readCountries.data && hasUsMilitaryItems.data !== undefined) {
      return async () => {
        setIsLoading(true);
        const enditemSet = eus.pnList.reduce((set, item) => {
          item.customerList?.forEach((customer) => {
            if (customer.enditem) {
              set.add(customer.enditem);
            }
          });
          return set;
        }, new Set<string>());

        const enditems: Record<string, string> = {};

        await Promise.allSettled(
          [...enditemSet].map(async (enditem) => {
            const info = await readItemInfo(enditem).unwrap();
            if (info.description) {
              enditems[enditem] = info.description;
            }
          }),
        );

        const workbook = createExcel({
          eus,
          hasUsMilitaryItems: hasUsMilitaryItems.data === true,
          title,
          programs: readPrograms.data?.map ?? {},
          unitOfMeasures: readUom.data ?? [],
          countries: readCountries.data?.byISO ?? {},
          enditems,
        });
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.ms-excel' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${title}.xlsx`;
        link.click();
        setIsLoading(false);
      };
    }
    return null;
  }, [eus, hasUsMilitaryItems.data, readCountries.data, readItemInfo, readPrograms.data, readUom.data, title]);

  return { download, isLoading, isReady };
}
