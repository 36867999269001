import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert, ProgramAutocomplete } from '@top-solution/microtecnica-mui';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { FormRow } from '../../../components/Form';
import { PartNumberAutocomplete } from '../../../components/Form/PartNumberAutocomplete';
import { SaveIcon } from '../../../components/Icons';
import { PnProgramAddForm, PnProgramAddFormSchema, ProcType } from '../../../entities/PnProgram';
import { useCreatePnPnProgramMutation } from '../../../services/pnProgramApi';

type PnProgramAddDialogProps = Omit<DialogProps, 'onClose' | 'children'> & {
  onClose: (pnProgram?: PnProgramAddForm) => void;
};

const defaultValues = {
  pn: null,
  programId: null,
  procType: null,
} as unknown as PnProgramAddForm;

export function PnProgramAddDialog(props: PnProgramAddDialogProps): JSX.Element {
  const { onClose, TransitionProps, ...dialogProps } = props;
  const { handleSubmit, control, reset } = useForm<PnProgramAddForm>({
    defaultValues,
    resolver: zodResolver(PnProgramAddFormSchema),
  });

  const [create, createStatus] = useCreatePnPnProgramMutation();

  const onSubmit = useCallback(
    async (data: PnProgramAddForm) => {
      await create(data).unwrap();
      onClose(data);
    },
    [create, onClose],
  );

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      {...dialogProps}
      TransitionProps={{
        ...TransitionProps,
        onExited: (node) => {
          reset();
          createStatus.reset();
          TransitionProps?.onExited?.(node);
        },
      }}
    >
      <DialogTitle>Nuova associazione P/N Program</DialogTitle>
      <DialogContent>
        <FormRow marginTop={1}>
          <Controller
            control={control}
            name="pn"
            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
              <PartNumberAutocomplete
                label="P/N"
                onChange={(_, value) => onChange(value)}
                disableClearable
                error={Boolean(error)}
                helperText={error?.message || ' '}
                fullWidth
                {...field}
              />
            )}
          />
        </FormRow>
        <Controller
          control={control}
          name="programId"
          render={({ field: { onChange, ...field }, fieldState: { error } }) => (
            <ProgramAutocomplete
              label="Programma"
              onChange={(_, value) => onChange(value)}
              disableClearable
              error={Boolean(error)}
              helperText={error?.message || ' '}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="procType"
          render={({ field: { value, ...field }, fieldState: { error } }) => (
            <TextField
              select
              fullWidth
              label="Proc Type"
              value={value ?? ''}
              error={Boolean(error)}
              helperText={error?.message || ' '}
              {...field}
            >
              {!value && <MenuItem value="">&nbsp;</MenuItem>}
              {Object.values(ProcType).map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        {createStatus.error && <ErrorAlert error={createStatus.error} />}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => onClose()}>
          Annulla
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          loading={createStatus.isLoading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
        >
          Salva
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
