import { ReactNode } from 'react';
import { ErrorAlert, UserLabel } from '@top-solution/microtecnica-mui';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { DefinitionList } from '../DefinitionList';
import { useReadProgramPmUsernameList } from './useReadProgramPmUsernameList';

interface PmNotificationListProps {
  programId: string;
  children?: ReactNode | ReactNode[];
}

export function PmNotificationList(props: PmNotificationListProps): JSX.Element {
  const { programId, children } = props;

  const readPMNotificationList = useReadProgramPmUsernameList(programId);

  return readPMNotificationList.isFetching ? (
    <CircularProgress />
  ) : readPMNotificationList.error ? (
    <ErrorAlert error={readPMNotificationList.error} />
  ) : (
    readPMNotificationList.data &&
    (readPMNotificationList.data.length === 0 ? (
      <Alert severity="warning">Non ci sono PM legati a questo programma</Alert>
    ) : (
      <>
        <DefinitionList
          data={[
            {
              term: 'PM OE',
              definition:
                readPMNotificationList.oe.length > 0
                  ? readPMNotificationList.oe.map((username) => (
                      <div key={username}>
                        <UserLabel username={username} />
                      </div>
                    ))
                  : '–',
            },
            {
              term: 'PM MRO',
              definition:
                readPMNotificationList.mro.length > 0
                  ? readPMNotificationList.mro.map((username) => (
                      <div key={username}>
                        <UserLabel username={username} />
                      </div>
                    ))
                  : '–',
            },
          ]}
        />
        {children}
      </>
    ))
  );
}
