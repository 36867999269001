import { Controller, UseFormReturn } from 'react-hook-form';
import { CountryAutocomplete, ProgramAutocomplete, UserAutocomplete, UserLabel } from '@top-solution/microtecnica-mui';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { EndUserForm, EndUserType, endUserTypeLabel } from '../../entities/EndUser';

function renderUserAutocompleteTags(values: string[], getTagProps: AutocompleteRenderGetTagProps): JSX.Element[] {
  return values.map((option, index) => (
    <Chip {...getTagProps({ index })} key={option} label={<UserLabel variant="body2" username={option} />} />
  ));
}

export interface EndUserEditFormProps {
  form: UseFormReturn<EndUserForm>;
}

export function EndUserEditForm(props: EndUserEditFormProps): JSX.Element {
  const { form } = props;

  const { control } = form;

  return (
    <>
      <Controller
        control={control}
        name="programId"
        render={({ field: { onChange, ...field }, fieldState: { error } }) => (
          <ProgramAutocomplete
            label="Programma"
            onChange={(_, value) => onChange(value)}
            disableClearable
            error={Boolean(error)}
            helperText={error?.message ?? ' '}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="name"
        render={({ field, fieldState: { error } }) => (
          <TextField label="Nome end-user" error={Boolean(error)} helperText={error?.message ?? ' '} {...field} />
        )}
      />
      <Controller
        control={control}
        name="type"
        render={({ field: { value, onChange, ...field }, fieldState: { error } }) => (
          <TextField
            label="Tipo"
            value={value ?? ''}
            onChange={(event) => onChange((event.target.value as EndUserType) || null)}
            error={Boolean(error)}
            helperText={error?.message ?? ' '}
            {...field}
            select
          >
            {Object.entries(endUserTypeLabel).map(([value, label]) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
            <MenuItem value="">&nbsp;</MenuItem>
          </TextField>
        )}
      />
      <Controller
        control={control}
        name="country"
        render={({ field: { onChange, ...field }, fieldState: { error } }) => (
          <CountryAutocomplete
            label="Nazione"
            onChange={(_, value) => onChange(value)}
            disableClearable
            error={Boolean(error)}
            helperText={error?.message ?? ' '}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="address"
        render={({ field, fieldState: { error } }) => (
          <TextField label="Indirizzo" error={Boolean(error)} helperText={error?.message ?? ' '} {...field} />
        )}
      />
      <Controller
        control={control}
        name="pmOeList"
        render={({ field: { onChange, ...field }, fieldState: { error } }) => (
          <UserAutocomplete
            multiple
            freeSolo
            label="PM OE"
            onChange={(_, value) => onChange(value)}
            error={Boolean(error)}
            helperText={
              (Array.isArray(error) ? error.find((item) => Boolean(item))?.message : error?.message) ??
              'Digitare il cognome e premere invio per selezionare'
            }
            renderTags={renderUserAutocompleteTags}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        name="pmMroList"
        render={({ field: { onChange, ...field }, fieldState: { error } }) => (
          <UserAutocomplete
            multiple
            freeSolo
            label="PM MRO"
            onChange={(_, value) => onChange(value)}
            error={Boolean(error)}
            helperText={
              (Array.isArray(error) ? error.find((item) => Boolean(item))?.message : error?.message) ??
              'Digitare il cognome e premere invio per selezionare'
            }
            renderTags={renderUserAutocompleteTags}
            {...field}
          />
        )}
      />
    </>
  );
}
