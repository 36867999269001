import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

export const TAG_TYPES = {
  VENDOR: 'Vendor',
  SUPPLIER: 'Supplier',
  CUSTOMER: 'Customer',
  END_USER: 'EndUser',
  ECCN: 'ECCN',
  SERNI_CATEGORY: 'SerniCategory',
  ITALIAN_CLASSIFICATION: 'ItalianClassification',
  FOREIGN_CLASSIFICATION: 'ForeignClassification',
  PURCHASE_GROUP: 'PurchaseGroup',
  THIRD_PARTY: 'ThirdParty',
  SOLDPN: 'SoldPn',
  EUS: 'EUS',
  EXPORT_LICENCE: 'ExportLicence',
  EUS_STATUS: 'Status',
  EUS_SUPPLIER_LICENSE_TYPE: 'SupplierLicenseType',
  EUS_TURNBACK: 'EUSTurnback',
  HISTORY: 'History',
  PN_PROGRAM: 'PnProgram',
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: (import.meta.env.VITE_API_BASE_URL as string) || '/api/',
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: Object.values(TAG_TYPES),
  endpoints: () => ({}),
});
